import React from 'react';
import { Box, Typography, Grid } from '@mui/material';

export function PrivacyPolicy() {
  return (
    <Box sx={{ padding: 5 }}>
      <Grid container spacing={5}>
        <Grid item xs={12} md={8}>
          <Typography variant="h4" component="h1" gutterBottom>
            POLÍTICA GERAL DE PRIVACIDADE DE DADOS PESSOAIS
          </Typography>
          <Typography paragraph>
            A <strong>NIARE CONSULTORIA EM TECNOLOGIA DA INFORMACAO LTDA</strong> garante ao usuário deste website o respeito pela sua privacidade, adotando as medidas necessárias para proteger os seus dados pessoais. Neste documento iremos descrever como os dados são protegidos nos processos de coleta, registro, armazenamento, uso, compartilhamento e eliminação.
          </Typography>

          <Typography variant="h5" component="h2" gutterBottom>
            I – DA ATUALIZAÇÃO DA POLÍTICA GERAL DE PRIVACIDADE DE DADOS PESSOAIS
          </Typography>
          <Typography paragraph>
            Sempre que a <strong>NIARE CONSULTORIA EM TECNOLOGIA DA INFORMACAO LTDA</strong> entender como necessário, esta política poderá sofrer alterações que serão válidas, eficazes e vinculantes a partir do momento que forem publicadas no website.
          </Typography>

          <Typography variant="h5" component="h2" gutterBottom>
            II - GLOSSÁRIO
          </Typography>
          <Typography paragraph>
            • <strong>Anonimização:</strong> utilização de meios técnicos razoáveis e disponíveis no momento do tratamento, por meio dos quais um dado perde a possibilidade de associação, direta ou indireta, a um indivíduo;
          </Typography>
          <Typography paragraph>
            • <strong>Controlador:</strong> pessoa natural ou jurídica, de direito público ou privado, a quem competem as decisões referentes ao Tratamento de Dados Pessoais;
          </Typography>
          <Typography paragraph>
            • <strong>Dado Pessoal:</strong> informação relacionada a pessoa natural identificada ou identificável;
          </Typography>
          <Typography paragraph>
            • <strong>Dado Pessoal Sensível:</strong> dado pessoal sobre origem racial ou étnica, convicção religiosa, opinião política, filiação a sindicato ou a organização de caráter religioso, filosófico ou político, dado referente à saúde ou à vida sexual, dado genético ou biométrico, quando vinculado a uma pessoa natural;
          </Typography>
          <Typography paragraph>
            • <strong>Encarregado de Proteção de Dados (DPO):</strong> Pessoa indicada pela Administração da <strong>NIARE CONSULTORIA EM TECNOLOGIA DA INFORMACAO LTDA</strong> para atuar como canal de comunicação entre o controlador, os titulares dos Dados Pessoais e a Autoridade Nacional de Proteção de Dados (ANPD);
          </Typography>
          <Typography paragraph>
            • <strong>Tratamento:</strong> toda operação realizada com Dados Pessoais, como as que se referem a coleta, produção, recepção, classificação, utilização, acesso, reprodução, transmissão, distribuição, processamento, arquivamento, armazenamento, eliminação, avaliação ou controle da informação, modificação, comunicação, transferência, difusão ou extração;
          </Typography>
          <Typography paragraph>
            • <strong>Usuários:</strong> todas as pessoas que visitarem e acessarem o Site. Também podemos nos referir ao Usuário e ao Titular de Dados Pessoais como “você”.
          </Typography>

          <Typography variant="h5" component="h2" gutterBottom>
            III - DA COLETA E DO TRATAMENTO DE DADOS
          </Typography>
          <Typography paragraph>
            A NIARE CONSULTORIA EM TECNOLOGIA DA INFORMACAO LTDA <strong>é a entidade responsável pela coleta e tratamento dos dados pessoais dos Usuários. Os seguintes dados pessoais recolhidos serão tratados e armazenados informaticamente</strong>:
          </Typography>
          <Typography paragraph>
            <strong>DADOS COLETADOS:</strong> Nome completo, E-mail, Telefone, Instituição de Ensino, Matrícula, Turno, Período, Data de Nascimento, RG, CPF, CNH, Passaporte, Carteira de Trabalho, Certidão de Nascimento, Foto, Comprovante de Matrícula, CEP, Endereço Completo.
          </Typography>
          <Typography paragraph>
            <strong>MOMENTO DA COLETA:</strong>
          </Typography>
          <Typography paragraph>
            (i) Quando realizamos o seu cadastro como cliente;
          </Typography>
          <Typography paragraph>
            (ii) Em casos de sugestões, dúvidas e/ou reclamações;
          </Typography>
          <Typography paragraph>
            (iii) Em visitas realizadas em nossa instituição.
          </Typography>
          <Typography paragraph>
            Os dados pessoais coletados serão conservados de forma a permitir a sua identificação apenas durante o período necessário para a prossecução das finalidades de coleta ou do tratamento posterior, findo o qual os mesmos serão arquivados ou eliminados, a depender da atividade de tratamento.
          </Typography>
          <Typography paragraph>
            A <strong>NIARE CONSULTORIA EM TECNOLOGIA DA INFORMACAO LTDA</strong> assume que os dados recolhidos foram inseridos pelo respetivo titular e que a sua inserção foi autorizada pelo mesmo, sendo os mesmos verdadeiros e exatos.
          </Typography>

          <Typography variant="h5" component="h2" gutterBottom>
            IV - DIREITOS DO USUÁRIO
          </Typography>
          <Typography paragraph>
            Nos termos da legislação aplicável, o titular do dado poderá fazer os seguintes requerimentos:
          </Typography>
          <ul>
            <li>Acesso e confirmação da existência de tratamento dos dados pessoais;</li>
            <li>Atualização, correção de dados incompletos, inexatos ou desatualizados;</li>
            <li>Anonimização, bloqueio ou eliminação de dados desnecessários, excessivos ou tratados em desconformidade com o disposto na LGPD;</li>
            <li>Portabilidade dos dados, observadas as normas aplicáveis e os segredos comercial e industrial;</li>
            <li>Informação das entidades públicas e privadas com as quais o Controlador realizou uso compartilhado de dados;</li>
            <li>Informação sobre a possibilidade de não fornecer consentimento e sobre as consequências da negativa;</li>
            <li>Revogação de consentimento que poderá ser realizada a qualquer momento e sem ônus, mediante manifestação expressa;</li>
            <li>Solicitar a eliminação dos Dados Pessoais Tratados com o consentimento, exceto nas hipóteses em que a manutenção dos dados é necessária ou permitida pela legislação;</li>
            <li>Oposição a Tratamento realizado com fundamento em outras bases legais, em caso de descumprimento da LGPD, ressaltando que pode haver situações em que poderemos continuar a realizar o Tratamento e recusar o seu pedido de oposição;</li>
            <li>Solicitar a revisão de decisões tomadas unicamente com base em Tratamento automatizado de Dados Pessoais que o afetem, como decisões de crédito;</li>
            <li>Solicitar o cancelamento do envio de ofertas direcionadas de produtos e serviços da <strong>NIARE CONSULTORIA EM TECNOLOGIA DA INFORMACAO LTDA</strong> pelos nossos canais.</li>
          </ul>

          <Typography paragraph>
            Estes direitos poderão ser exercidos mediante pedido escrito dirigido ao e-mail sac@estudante.cc.
          </Typography>

          <Typography variant="h5" component="h2" gutterBottom>
            V - RECLAMAÇÕES E DÚVIDAS
          </Typography>
          <Typography paragraph>
            Para obter mais informações sobre a Política Geral de Privacidade de Dados Pessoais ou sobre como tratamos os seus dados pessoais, você pode entrar em contato no e-mail sac@estudante.cc com o assunto “aos cuidados do Encarregado de Dados”.
          </Typography>
          <Typography paragraph>
            A <strong>NIARE CONSULTORIA EM TECNOLOGIA DA INFORMACAO LTDA</strong> informa que o prazo para resposta de reclamações e/ou dúvidas é de até 15 (quinze) dias.
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
}
