import { Box, Typography, Card, CardContent } from "@mui/material";
import { useOrder } from "../../hooks/useOrder";

export function OrderShow() {
  const { order } = useOrder();

  return (
    <Box sx={{ padding: 1 }}>
      {order.kind === "physical" ? (
        <Card sx={{ backgroundColor: "primary.main", padding: 2, borderRadius: 2, color: "white" }}>
          <CardContent>
            <Typography variant="body1">
              <i className="fa fa-info-circle" style={{ marginRight: 8, fontSize: 24 }}></i>
              A sua carteirinha não é digital e não pode ser visualizada pelo nosso sistema. Sua
              carteirinha física chegará em breve pelos correios.
            </Typography>
          </CardContent>
        </Card>
      ) : (
        <Box>
          {/* Exibição da Carteirinha Digital */}
          <Box
            sx={{
              position: "relative",
              textAlign: "center",
              marginBottom: 5,
              width: 300, // Define a largura desejada para o SVG
              height: "auto",
              marginX: "auto", // Centraliza o elemento na tela
            }}
          >
            <div
              className={`card-html-${order.design_id === 2 ? 'pink' : order.design_id === 1 ? 'green' : 'black'}`}
              dangerouslySetInnerHTML={{
                __html: order.card_svg_html,
              }}
              style={{
                width: "100%", // Garante que o SVG ocupe toda a largura do Box
                height: "100%", // Ajusta a altura proporcionalmente
              }}
            />
            <img
              src={order.card_photo}
              alt="Foto da carteirinha"
              style={{
                position: "absolute",
                top: "50%",
                left: "7%", // Ajuste para posicionar mais à esquerda, mantendo uma margem
                transform: "translateY(-50%)",
                width: 75,
                height: 75,
                borderRadius: 0, // Remove o arredondamento
              }}
            />
          </Box>

          {/* QRCode */}
          <Typography variant="h5" textAlign="center" gutterBottom>
            QRCode de validação:
          </Typography>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              padding: 2,
              backgroundColor: "white", // Fundo branco
              borderRadius: 2,
              marginTop: 2,
              width: "80%", // Permite que o QR Code ocupe mais espaço na tela
              marginX: "auto",
            }}
            dangerouslySetInnerHTML={{
              __html: order.qrcode_svg,
            }}
          />
        </Box>
      )}
    </Box>
  );
}
