import React from 'react';
import { createRoot } from 'react-dom/client'; // Importa corretamente o createRoot
import App from './App';

// Encontra o elemento root no DOM
const container = document.getElementById('root');
const root = createRoot(container); // Cria a instância de root

// Renderiza o app dentro do root
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
