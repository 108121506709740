import { OrderSteps } from "../../components/OrderSteps";
import { OrderContextProvider } from "../../contexts/OrderContext";
import { SideBarLeft } from "../../components/SideBarLeft";
import { SideBarRight } from "../../components/SideBarRight";
import { Grid, Box } from "@mui/material";
import { useState } from "react";

export function OrderForm() {
  // Supondo que você tem um controle local do step
  const [activeStep, setActiveStep] = useState(0); // 0 para o primeiro step, você pode atualizar isso dinamicamente

  return (
    <OrderContextProvider>
      <Grid container sx={{ minHeight: "100vh", backgroundColor: "background.default" }}>
        {/* Sidebar Esquerda */}
        <Grid item xs={0} md={2} lg={2} xl={2}>
          <SideBarLeft activeStep={activeStep} /> {/* Passa o activeStep para a SideBarLeft */}
        </Grid>

        {/* Conteúdo Principal */}
        <Grid item xs={12} md={8} lg={8} xl={8}>
          <OrderSteps setActiveStep={setActiveStep} /> {/* Você pode usar setActiveStep dentro de OrderSteps */}
        </Grid>

        {/* Sidebar Direita */}
        <Grid item xs={0} md={2} lg={2} xl={2}>
          <SideBarRight />
        </Grid>
      </Grid>
    </OrderContextProvider>
  );
}
