import React, { useEffect, useState, useRef } from "react";
import { ReactCrop } from 'react-image-crop';
import {
  Box,
  Typography,
  Button,
  CircularProgress,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  List,
  ListItem,
  ListItemIcon,
  ListItemText
} from "@mui/material";
import { PhotoCamera, ArrowForward, FileUpload, Face, FilterNone, Wallpaper, EmojiEmotions } from "@mui/icons-material";
import { useOrder } from "../../../hooks/useOrder";
import api from "../../../services/api";
import "react-image-crop/dist/ReactCrop.css";

export function PhotoUpload() {
  const { order, setOrder } = useOrder();
  const [photo, setPhoto] = useState(null);
  const [preview, setPreview] = useState(null);
  const imgRef = useRef(null);
  const [crop, setCrop] = useState({
    unit: "px",
    width: 100,
    height: 100,
    x: 0,
    y: 0,
    aspect: 1,
  });
  const [completedCrop, setCompletedCrop] = useState(null);
  const [croppedBlob, setCroppedBlob] = useState(null);
  const [uploadError, setUploadError] = useState(null);
  const [requestLoading, setRequestLoading] = useState(false);
  const [openCropDialog, setOpenCropDialog] = useState(false);

  async function handlerPhotoUpload(e) {
    e.preventDefault();
    if (!photo) {
      setUploadError("Adicione uma foto antes de prosseguir.");
      return;
    }

    if (!croppedBlob) {
      setUploadError("Erro ao processar a imagem. Por favor, tente novamente.");
      return;
    }

    setRequestLoading(true);

    const resizedBlob = await resizeImg(croppedBlob);
    const finalFile = new File([resizedBlob], "finalImage.jpg", {
      type: resizedBlob.type,
    });

    const dataObject = new FormData();
    dataObject.append("order[card_photo]", finalFile);

    try {
      const response = await api.put(`orders/${order.identifier}`, dataObject);
      setOrder(response.data);
    } catch (error) {
      console.error("Failed to upload photo:", error);
      setUploadError("Falha ao enviar a foto. Por favor, tente novamente.");
    } finally {
      setRequestLoading(false);
      setOpenCropDialog(false);
    }
  }

  function handlePhoto(e) {
    const imageFiles = e.target.files;
    if (imageFiles && imageFiles.length > 0) {
      const imageFile = imageFiles[0];
      const objectUrl = URL.createObjectURL(imageFile);
      setPreview(objectUrl);
      setPhoto(imageFile);
      setOpenCropDialog(true);
      setCrop(undefined);
      setCompletedCrop(null);
      setCroppedBlob(null);
    } else {
      resetCrop();
    }
    setUploadError(null);
  }

  function resetCrop() {
    setPhoto(null);
    setPreview(null);
    setCrop(undefined);
    setCompletedCrop(null);
    setCroppedBlob(null);
  }

  function onImageLoad(e) {
    const img = e.currentTarget;
    imgRef.current = img;

    const { width, height } = img;
    const cropSize = Math.min(width, height) * 0.8;
    const x = (width - cropSize) / 2;
    const y = (height - cropSize) / 2;

    const initialCrop = {
      unit: "px",
      x,
      y,
      width: cropSize,
      height: cropSize,
      aspect: 1,
    };

    setCrop(initialCrop);
  }

  useEffect(() => {
    if (completedCrop?.width && completedCrop?.height && imgRef.current) {
      generateCroppedImage(imgRef.current, completedCrop);
    }
  }, [completedCrop]);

  async function generateCroppedImage(image, crop) {
    if (!crop || !image) return;

    const canvas = document.createElement("canvas");
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width * scaleX;
    canvas.height = crop.height * scaleY;
    const ctx = canvas.getContext("2d");

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width * scaleX,
      crop.height * scaleY
    );

    return new Promise((resolve) => {
      canvas.toBlob((blob) => {
        setCroppedBlob(blob);
        resolve();
      }, "image/jpeg");
    });
  }

  function resizeImg(blob) {
    return new Promise((resolve) => {
      const img = new Image();
      img.onload = () => {
        const MAX_SIZE = 200;
        const canvas = document.createElement("canvas");
        canvas.width = MAX_SIZE;
        canvas.height = MAX_SIZE;
        const ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0, MAX_SIZE, MAX_SIZE);
        canvas.toBlob((resizedBlob) => resolve(resizedBlob), "image/jpeg");
      };
      img.src = URL.createObjectURL(blob);
    });
  }

  useEffect(() => {
    if (!photo) {
      setPreview(null);
      return;
    }
    return () => URL.revokeObjectURL(preview);
  }, [photo]);

  return (
    <Box sx={{ padding: 4, textAlign: "center", maxWidth: '600px', margin: '0 auto', borderRadius: 2, boxShadow: 3, backgroundColor: '#f9f9f9' }}>
      <Typography
        variant="h4"
        textAlign="center"
        gutterBottom
        sx={{ fontWeight: 500, marginBottom: 3, color: '#333' }}
      >
        Tire uma foto nova ou envie uma foto existente
      </Typography>

      <Typography variant="body1" color='#000000' gutterBottom>
        A foto será usada para sua carteirinha. Siga as dicas:
      </Typography>

      {/* Lista de passos com ícones */}
      <List sx={{ maxWidth: 500, margin: '0 auto', textAlign: 'left' }}>
        <ListItem>
          <ListItemIcon>
            <Face sx={{ color: '#1976d2' }} />
          </ListItemIcon>
          <ListItemText primary="Foto de frente, com rosto centralizado." sx={{ color: '#000' }} />
        </ListItem>

        <ListItem>
          <ListItemIcon>
            <FilterNone sx={{ color: '#1976d2' }} />
          </ListItemIcon>
          <ListItemText primary="Sem filtros ou efeitos." sx={{ color: '#000' }} />
        </ListItem>

        <ListItem>
          <ListItemIcon>
            <Wallpaper sx={{ color: '#1976d2' }} />
          </ListItemIcon>
          <ListItemText primary="Fundo claro e neutro." sx={{ color: '#000' }} />
        </ListItem>

        <ListItem>
          <ListItemIcon>
            <EmojiEmotions sx={{ color: '#1976d2' }} />
          </ListItemIcon>
          <ListItemText primary="Evite chapéus ou óculos que cubram o rosto." sx={{ color: '#000' }} />
        </ListItem>
      </List>

      <Box display="flex" justifyContent="center" gap={2} mt={3}>
        <IconButton
          color="primary"
          component="label"
          sx={{
            borderRadius: 2,
            padding: 2,
            backgroundColor: "#1976d2",
            color: "white",
            "&:hover": { backgroundColor: "#1565c0" },
          }}
        >
          <PhotoCamera />
          <input type="file" accept="image/*" hidden onChange={handlePhoto} />
        </IconButton>

        <Button
          variant="contained"
          component="label"
          startIcon={<FileUpload />}
          sx={{
            padding: "10px 20px",
            borderRadius: 2,
            backgroundColor: "#1976d2",
            color: "white",
            "&:hover": { backgroundColor: "#1565c0" },
          }}
        >
          Carregar Foto
          <input type="file" accept="image/*" hidden onChange={handlePhoto} />
        </Button>
      </Box>

      <Typography color="error" textAlign="center" mt={2}>
        {uploadError || (order.errors && order.errors.photo)}
      </Typography>

      {requestLoading && (
        <Box display="flex" justifyContent="center" mt={3}>
          <CircularProgress />
        </Box>
      )}

      <Dialog
        open={openCropDialog}
        onClose={() => setOpenCropDialog(false)}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>Recortar Foto</DialogTitle>
        <DialogContent>
          {preview && (
            <ReactCrop
              crop={crop}
              onChange={(newCrop) => setCrop(newCrop)}
              onComplete={(c) => setCompletedCrop(c)}
              aspect={1}
              keepSelection
            >
              <img
                src={preview}
                alt="minha foto"
                onLoad={onImageLoad}
                style={{ maxWidth: "100%" }}
              />
            </ReactCrop>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handlerPhotoUpload}
            color="primary"
            variant="contained"
            disabled={requestLoading}
            endIcon={<ArrowForward />}
          >
            Confirmar Recorte
          </Button>
          <Button onClick={() => setOpenCropDialog(false)} color="secondary">
            Cancelar
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
