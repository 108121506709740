import axios from "axios";
import Qs from "qs";

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

api.interceptors.request.use(async (config) => {
  const token = window.localStorage.getItem("@app/token");
  const site = window.localStorage.getItem("@app/site");

  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }

  config.headers["Access-Control-Allow-Origin"] = "*";
  config.headers["site"] = site;
  config.paramsSerializer = (params) => {
    return Qs.stringify(params, {
      arrayFormat: "brackets",
      encode: false,
    });
  };

  return config;
});

api.interceptors.response.use(
  (response) => {
    return response;
  },
  async function (error) {
    if (axios.isCancel(error) || error.code === 'ERR_CANCELED') {
      return Promise.reject(error);
    }

    if (error.response) {
      const status = error.response.status;
    }

    return Promise.reject(error);
  }
);

export default api;
