import { Box } from "@mui/material";
import { useTheme } from "@mui/material/styles";

export function SideBarRight() {
  return (
    <Box
      sx={{
        display: { xs: "none", md: "block" }, // Esconde no mobile, mostra em tablets e telas maiores
        backgroundColor: "black",
        padding: 2, // Padding para adicionar espaçamento interno
        height: "100%", // Sidebar ocupa a altura total da tela
        boxShadow: 3, // Adiciona uma sombra suave para destacar a sidebar
      }}
    >
      {/* Conteúdo da Sidebar pode ser adicionado aqui */}
    </Box>
  );
}
