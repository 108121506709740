import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';




export function AdminOrderDetail() {
    const { orderId } = useParams();
    const [order, setOrder] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchOrder = async () => {
            try {
                const response = await axios.get(`/orders/${orderId}`);
                setOrder(response.data);
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        fetchOrder();
    }, [orderId]);

    if (loading) return <div>Loading...</div>;
    if (error) return <div>Error: {error}</div>;

    return (
        <div>
            <h1>Order Detail</h1>
            {order && (
                <div>
                    <p>Order ID: {order.id}</p>
                    <p>Customer Name: {order.customerName}</p>
                    <p>Order Date: {order.orderDate}</p>
                    <p>Status: {order.status}</p>
                    {/* Add more order details as needed */}
                </div>
            )}
        </div>
    );
};