import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Box, Typography, Card, CardContent, Button, Avatar } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorIcon from "@mui/icons-material/Error";
import api from "../../services/api";

export function StudentCardStatus() {
  const { identifier } = useParams();
  const [order, setOrder] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    api.get(`/student_cards/${identifier}`)
      .then(response => {
        setOrder(response.data.order);
        setLoading(false);
      })
      .catch(error => {
        console.error("Erro ao buscar dados do pedido:", error);
        setLoading(false);
      });
  }, [identifier]);

  if (loading) {
    return <Typography>Carregando...</Typography>;
  }

  if (!order) {
    return <Typography>Pedido não encontrado.</Typography>;
  }

  return (
    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", minHeight: "100vh", padding: 2 }}>
      <Card
        sx={{
          backgroundColor: order.active ? "success.main" : "error.main",
          color: "white",
          padding: 3,
          borderRadius: 2,
          textAlign: "center",
          width: "100%",
          maxWidth: 600,
        }}
      >
        <CardContent>
          {order.active ? (
            <>
              <CheckCircleIcon sx={{ fontSize: 64, color: "white", marginBottom: 2 }} />
              <Typography variant="h5">Carteirinha válida!</Typography>
            </>
          ) : order.expired ? (
            <>
              <ErrorIcon sx={{ fontSize: 64, color: "white", marginBottom: 2 }} />
              <Typography variant="h5">
                Carteirinha venceu em {order.expiration_date}
              </Typography>
            </>
          ) : (
            <>
              <ErrorIcon sx={{ fontSize: 64, color: "white", marginBottom: 2 }} />
              <Typography variant="h5">Essa carteirinha ainda não foi aprovada</Typography>
            </>
          )}

          <Box sx={{ textAlign: "left", marginTop: 3 }}>
            <Typography variant="body1"><strong>Nome:</strong> {order.full_name}</Typography>
            <Typography variant="body1"><strong>Instituição de ensino:</strong> {order.institution_name}</Typography>
            <Typography variant="body1"><strong>Código de uso:</strong> {order.activation_code}</Typography>
          </Box>

          {order.active && order.download_url && (
            <Button
              variant="contained"
              color="primary"
              href={order.download_url}
              target="_blank"
              sx={{ marginTop: 4, width: "100%", fontSize: "1rem", padding: 1.5 }}
            >
              Baixar certificado de atributos
            </Button>
          )}
        </CardContent>
      </Card>
    </Box>
  );
}
