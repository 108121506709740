import { createTheme } from '@mui/material/styles';
import { green, yellow, pink, cyan, orange } from '@mui/material/colors';

const theme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#800080',
    },
    secondary: {
      main: pink[500],
    },
    background: {
      default: 'rgb(47, 5, 73)',
      paper: '#121212',
    },
    text: {
      primary: '#FFFFFF',
      secondary: '#D27CFC',
      orange: '#D27CFC',
    },
    error: {
      main: '#ccff00',
    },
    warning: {
      main: yellow[500],
    },
    info: {
      main: cyan[200],
    },
    textconclued:{
      main: '#ffffff',
    },
    success: {
      main: green[400],
    },
  },
  typography: {
    fontFamily: '"Nunito", "Roboto", "Helvetica", "Arial", sans-serif',
    fontSize: 14,
    h1: {
      fontFamily: '"Nunito", sans-serif',
      fontSize: '3rem',
      fontWeight: 700,
      color: '#FF4081',
    },
    h2: {
      fontFamily: '"Nunito", sans-serif',
      fontSize: '2.5rem',
      fontWeight: 500,
      color: '#76FF03',
    },
    button: {
      fontFamily: '"Nunito", sans-serif',
      fontWeight: 600,
      textTransform: 'uppercase',
      color: '#FFFFFF',
    },
    body1: {
      fontFamily: '"Nunito", sans-serif',
    },
    body2: {
      fontFamily: '"Nunito", sans-serif',
      fontSize: '1.5rem',
    },
    subtitle1: {
      fontFamily: '"Nunito", sans-serif',
    },
    subtitle2: {
      fontFamily: '"Nunito", sans-serif',
    },
    caption: {
      fontFamily: '"Nunito", sans-serif',
    },
    overline: {
      fontFamily: '"Nunito", sans-serif',
    },
  },
});


export default theme;
