import React, { useEffect, useState } from 'react';
import { Container, Typography, CircularProgress, Grid, Card, CardContent, CardActions, Button, Box } from '@mui/material';
import { Link } from 'react-router-dom';
import api from "../../services/api";
import { ThemeProvider } from '@mui/material/styles';
import theme from '../../theme';

export function PostsList() {
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchPosts = async () => {
      try {
        console.log("Fetching all posts..."); // Log para indicar o início da requisição
        const response = await api.get('/posts');
        console.log("Posts data received:", response.data); // Log para verificar os dados recebidos
        setPosts(response.data);
      } catch (error) {
        console.error("Error fetching posts:", error); // Log de erro
        setError('Erro ao carregar os posts');
      } finally {
        setLoading(false);
      }
    };

    fetchPosts();
  }, []);

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
        <Typography color="error">{error}</Typography>
      </Box>
    );
  }

  return (
    <ThemeProvider theme={theme}>
      <Container>
        <Typography variant="h4" gutterBottom>Posts</Typography>
        <Grid container spacing={4}>
          {posts.map((post) => (
            <Grid item xs={12} sm={6} md={4} key={post.id}>
              <Card>
                <CardContent>
                  <Typography variant="h5">{post.title}</Typography>
                  <Typography variant="body2" color="textSecondary">{post.description}</Typography>
                </CardContent>
                <CardActions>
                  <Button size="small" color="primary" component={Link} to={`/blog/${post.slug}`}>
                    Ler Mais
                  </Button>
                </CardActions>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>
    </ThemeProvider>
  );
}
