import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Container, Typography, CircularProgress, Box, Card, CardContent } from '@mui/material';
import { Person as PersonIcon, ErrorOutline as ErrorIcon } from '@mui/icons-material';
import { ThemeProvider } from '@mui/material/styles';
import theme from '../../theme';
import api from "../../services/api";

export function PostDetail() {
  const { slug } = useParams();
  const [post, setPost] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchPost = async () => {
      try {
        console.log("Fetching post with slug:", slug);  // Log para verificar o slug
        const response = await api.get(`/posts/${slug}`);
        console.log("Post data received:", response.data);  // Log para verificar os dados recebidos
        setPost(response.data);
      } catch (error) {
        console.error("Error fetching post:", error);  // Log para capturar o erro
        setError('Erro ao carregar o post');
      } finally {
        setLoading(false);
      }
    };

    fetchPost();
  }, [slug]);

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
        <ErrorIcon color="error" style={{ marginRight: 8 }} />
        <Typography color="error">{error}</Typography>
      </Box>
    );
  }

  return (
    <ThemeProvider theme={theme}>
      <Container>
        <Card>
          <CardContent>
            <Typography variant="h4" gutterBottom>{post.title}</Typography>
            <Box display="flex" alignItems="center" mb={2}>
              <PersonIcon color="action" />
              <Typography variant="body1" color="textSecondary" style={{ marginLeft: 8 }}>
                {post.author}
              </Typography>
            </Box>
            <Typography variant="body1">{post.content}</Typography>
          </CardContent>
        </Card>
      </Container>
    </ThemeProvider>
  );
}
