import React from "react";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import { SignIn } from './pages/SignIn';
import { SignUp } from './pages/SignUp';
import { useAuth } from './hooks/useAuth';
import { OrderForm } from "./pages/OrderForm";
import { PrivacyPolicy } from "./pages/PrivacyPolicy";
import { Terms } from "./pages/Terms";
import { Orders } from "./pages/Orders";
import { StudentCardStatus } from "./pages/StudentCardStatus";
import { Home } from "./pages/Home";
import { ShelvedOrders } from "./pages/ShelvedOrders";
import { InitializeOrder } from "./pages/InitializeOrder";
import { Congratulations } from "./pages/Congratulations";
import { RejectRequest } from "./pages/RejectRequest";
import { ForgotPassword } from "./pages/ForgotPassword";
import { ResetPassword } from "./pages/ResetPassword";
import { PostsList } from './pages/PostsList';
import { PostDetail } from './pages/PostDetail';
import { AdminOrders } from './pages/Admin/AdminOrders';
import { AdminOrderDetail } from './pages/Admin/AdminOrderDetail';

function AuthenticatedRoute({ children }) {
  const { isAuthenticated } = useAuth();
  return isAuthenticated() ? children : <Navigate to="/sign_in" />;
}

function PublicRoute({ children }) {
  return <>{children}</>;
}
function AdminRoute({ children }) {
  const { isAdmin } = useAuth();
  return isAdmin() ? children : <Navigate to="/sign_in" />;
}

export function AppRoutes() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<PublicRoute><Home /></PublicRoute>} />
        <Route path="/privacy_policy" element={<PublicRoute><PrivacyPolicy /></PublicRoute>} />
        <Route path="/sign_in" element={<PublicRoute><SignIn /></PublicRoute>} />
        <Route path="/sign_up" element={<PublicRoute><SignUp /></PublicRoute>} />
        <Route path="/forgot_password" element={<PublicRoute><ForgotPassword /></PublicRoute>} />
        <Route path="/reset_password" element={<PublicRoute><ResetPassword /></PublicRoute>} />
        <Route path="/orders/:id" element={<PublicRoute><OrderForm /></PublicRoute>} />
        <Route path="/orders" element={<AuthenticatedRoute><Orders /></AuthenticatedRoute>} />
        <Route path="/student_card_status/:identifier" element={<PublicRoute><StudentCardStatus /></PublicRoute>} />
        <Route path="/shelved_orders" element={<AuthenticatedRoute><ShelvedOrders /></AuthenticatedRoute>} />
        <Route path="/terms" element={<PublicRoute><Terms /></PublicRoute>} />
        <Route path="/:site/initializeOrder" element={<PublicRoute><InitializeOrder /></PublicRoute>} />
        <Route path="/congratulations/:order_id" element={<PublicRoute><Congratulations /></PublicRoute>} />
        <Route path="/rejectRequest/:order_id" element={<PublicRoute><RejectRequest /></PublicRoute>} />
        <Route path="/blog" element={<PublicRoute><PostsList /></PublicRoute>} />
        <Route path="/blog/:slug" element={<PublicRoute><PostDetail /></PublicRoute>} />
        <Route path="/admin_orders" element={<AuthenticatedRoute><AdminOrders /></AuthenticatedRoute>} />
        <Route path="/admin_orders_show" element={<AuthenticatedRoute><AdminOrderDetail /></AuthenticatedRoute>} />
        <Route path="*" element={<h1>Página não encontrada</h1>} />
      </Routes>
    </BrowserRouter>
  );
}

export default AppRoutes;