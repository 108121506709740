import { useState } from "react";
import { useOrder } from "../../../hooks/useOrder";
import api from "../../../services/api";
import FileImage from "../../../assets/images/file.svg";
import { useNavigate } from 'react-router-dom';
import ReactCrop, { centerCrop, makeAspectCrop } from "react-image-crop";
import { useDebouncedCallback } from "use-debounce";
import { Snackbar, Alert } from "@mui/material";

export function Adjustments({ adjustments }) {
  const { order, setOrder } = useOrder();

  const [preview, setPreview] = useState("");
  const [photo, setPhoto] = useState(undefined);
  const [crop, setCrop] = useState({
    unit: "%",
    x: 25,
    y: 25,
    width: 50,
    height: 50,
  });
  const [croppedImage, setCroppedImage] = useState();
  const [croppedImageURL, setCroppedImageURL] = useState();
  const [requestLoading, setRequestLoading] = useState(false);

  const navigate = useNavigate();

  const [newOrder, setNewOrder] = useState({
    full_name: order.full_name,
    cpf: order.cpf,
    cep: order.cep,
    street_name: order.street_name,
    street_number: order.street_number,
    street_complement: order.street_complement,
    neighborhood: order.neighborhood,
    birth_date: order.birth_date,
    phone_number: order.phone_number,
    document_number: order.document_number,
    institution_name: order.institution_name,
    course_name: order.course_name,
    custom_course: order.custom_course,
    registration_number: order.registration_number,
    period: order.period,
    time: order.time,
  });

  const [imagePreviews, setImagePreviews] = useState({
    photo: "",
    card_photo: "",
    institution_statement_photo: "",
    card_institution_statement_photo: "",
    document_photo_front: "",
    card_document_photo_front: "",
    document_photo_back: "",
    card_document_photo_back: "",
  });

  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");

  const fileUploadFieldNames = [
    "photo",
    "card_photo",
    "document_photo_front",
    "card_document_photo_front",
    "document_photo_back",
    "card_document_photo_back",
    "institution_statement_photo",
    "card_institution_statement_photo"
  ];

  const handleNewOrderChange = (field, value) => {
    const currentNewOrder = { ...newOrder };
    currentNewOrder[field] = value;

    if (fileUploadFieldNames.includes(field)) {
      const currentImagesPreviews = { ...imagePreviews };
      currentImagesPreviews[field] = URL.createObjectURL(value);
      setImagePreviews(currentImagesPreviews);
    }

    setNewOrder(currentNewOrder);
  };

  const getHeightAndWidthFromDataUrl = dataURL =>
    new Promise(resolve => {
      const img = new Image();
      img.onload = () => resolve({ height: img.height, width: img.width, image: img });
      img.src = dataURL;
    });

  function resizeImg(imageObject) {
    const MAX_HEIGHT = 200;
    let width = imageObject.width;
    let height = imageObject.height;

    if (height > MAX_HEIGHT) {
      width = width * (MAX_HEIGHT / height);
      height = MAX_HEIGHT;
    }

    let canvas = document.createElement("canvas");
    canvas.width = width;
    canvas.height = height;
    let ctx = canvas.getContext("2d");
    ctx.drawImage(imageObject.image, 0, 0, width, height);
    try {
      return new Promise((resolve) => {
        canvas.toBlob((file) => {
          resolve(file);
        }, "image/jpeg");
      });
    } catch (error) {
      return null;
    }
  }

  function getCroppedImg(sourceImage, crop, fileName) {
    const canvas = document.createElement("canvas");
    canvas.width = sourceImage.naturalWidth * (crop.width / 100);
    canvas.height = sourceImage.naturalHeight * (crop.height / 100);
    const ctx = canvas.getContext("2d");
    ctx.drawImage(
      sourceImage,
      sourceImage.naturalWidth * (crop.x / 100),
      sourceImage.naturalHeight * (crop.y / 100),
      sourceImage.naturalWidth * (crop.width / 100),
      sourceImage.naturalHeight * (crop.height / 100),
      0,
      0,
      sourceImage.naturalWidth * (crop.width / 100),
      sourceImage.naturalHeight * (crop.height / 100)
    );
    try {
      return new Promise((resolve) => {
        canvas.toBlob((file) => {
          resolve(file);
        }, "image/jpeg");
      });
    } catch (error) {
      return null;
    }
  }

  async function handlePhoto(e) {
    const image = e.target.files;
    if (image) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setPreview(e.target.result);
      };
      reader.readAsDataURL(image[0]);
      setPhoto(image[0]);
    } else {
      setPhoto(undefined);
    }
  }

  async function makeClientCrop(crop) {
    if (croppedImage && crop.width && crop.height) {
      return await getCroppedImg(croppedImage, crop, "newFile.jpeg");
    }
  }

  async function convertAndUpdateImage() {
    const blob = await makeClientCrop(crop);
    const newFile = new File([blob], "newFile.jpg", {
      type: blob.type,
    });
    setCroppedImageURL(newFile);
  }

  async function handleCropChange(crop, percentCrop) {
    setCrop(percentCrop);
    debouncedHandleUpdateImage();
  }

  const debouncedHandleUpdateImage = useDebouncedCallback(async () => {
    await convertAndUpdateImage();
  }, 500);

  function onImageLoad(e) {
    const { width, height } = e.currentTarget;
    setCroppedImage(e.currentTarget);

    const crop = centerCrop(
      makeAspectCrop({ unit: "%", width: 80 }, 1, width, height),
      width,
      height
    );
    setCrop(crop);
  }

  async function handlerPhotoUpload(e) {
    e.preventDefault();
    setRequestLoading(true);
    const dataObject = new FormData();
    const fileAsDataURL = window.URL.createObjectURL(croppedImageURL);
    const imageObject = await getHeightAndWidthFromDataUrl(fileAsDataURL);

    const blob = await resizeImg(imageObject);
    const resizedFile = new File([blob], "resizedFile.jpg", {
      type: blob.type,
    });

    dataObject.append("order[card_photo]", resizedFile);
    const response = await api.put(`orders/${order.identifier}`, dataObject);

    setRequestLoading(false);
    setOrder(response.data);
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    const dataObject = new FormData();
    if (newOrder.photo) dataObject.append("order[photo]", newOrder["photo"]);
    if (newOrder.card_photo) dataObject.append("order[card_photo]", newOrder["card_photo"]);
    if (newOrder.card_document_photo_front)
      dataObject.append("order[card_document_photo_front]", newOrder["card_document_photo_front"]);
    if (newOrder.card_document_photo_back)
      dataObject.append("order[card_document_photo_back]", newOrder["card_document_photo_back"]);
    if (newOrder.card_institution_statement_photo)
      dataObject.append("order[card_institution_statement_photo]", newOrder["card_institution_statement_photo"]);
    dataObject.append("order[full_name]", newOrder["full_name"]);
    dataObject.append("order[cpf]", newOrder["cpf"]);
    dataObject.append("order[cep]", newOrder["cep"]);
    dataObject.append("order[street_name]", newOrder["street_name"]);
    dataObject.append("order[street_number]", newOrder["street_number"]);
    dataObject.append("order[street_complement]", newOrder["street_complement"]);
    dataObject.append("order[neighborhood]", newOrder["neighborhood"]);
    dataObject.append("order[birth_date]", newOrder["birth_date"]);
    dataObject.append("order[phone_number]", newOrder["phone_number"]);
    dataObject.append("order[document_number]", newOrder["document_number"]);
    dataObject.append("order[institution_name]", newOrder["institution_name"]);
    dataObject.append("order[course_name]", newOrder["course_name"]);
    dataObject.append("order[custom_course]", newOrder["custom_course"]);
    dataObject.append("order[registration_number]", newOrder["registration_number"]);
    dataObject.append("order[period]", newOrder["period"]);
    dataObject.append("order[time]", newOrder["time"]);
    const response = await api.put(`/orders/${order.identifier}`, dataObject);
    setOrder(response.data);

    if (response.data.success) {
      showSnackbar("Sua carteirinha está em processo de aprovação.", "success");
      navigate(`/orders`);
    } else {
      showSnackbar("Ocorreu um erro ao enviar os ajustes.", "error");
    }
  };

  const showSnackbar = (message, severity) => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setOpenSnackbar(true);
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  return (
    <div>
      <div className="container">
        <div className="row">
          {adjustments.map((adjustment) => {
            if (fileUploadFieldNames.includes(adjustment.field)) {
              return (
                <div key={adjustment.field}>
                  <AdjustmentsStyle>
                    <p className="top-main-title">Essas são as informações que precisam ser alteradas para continuarmos com o seu pedido.</p>
                    <div className="">
                      <div className="row">
                        <div className="text-center">
                          {adjustment.field == "card_photo" ? (
                            <>
                            <p>{translatedFields[adjustment.field]}</p>
                            {handlePhotoFeedback(
                              newOrder[adjustment.field],
                              adjustment.field
                            )}
                            </>
                          ) : (
                            <>
                            <p>{translatedFields[adjustment.field]}</p>
                            {handleFileUpload(
                              newOrder[adjustment.field],
                              adjustment.field
                            )}
                            </>
                          )}
                          <div>
                            <p className="end-text">
                              <span className="text-danger fw-bold">Mensagem:</span> {adjustment.comment}
                            </p>
                          </div>
                          <div>
                            <p className="end-text">
                              <strong>Clique aqui</strong> para saber como devem
                              ser as fotos.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </AdjustmentsStyle>
                </div>
              );
            } else {
              return (
                <div>
                  <div className="mt-3 mb-3" key={adjustment.field}>
                    <div className="row">
                      <div className="col-md-3">
                        <p>{translatedFields[adjustment.field]}</p>
                      </div>
                      <div className="col-md-8">
                        <input
                          className="w-100"
                          value={newOrder[adjustment.field]}
                          placeholder="Faça as suas alteraçoes nesse campo"
                          onChange={(e) =>
                            handleNewOrderChange(
                              adjustment.field,
                              e.target.value
                            )
                          }
                        />
                        <p className="text-danger mt-3">{adjustment.comment}</p>
                        {order.errors && order.errors[adjustment.field] && (
                          <p className="text-danger mt-3">
                            {order.errors[adjustment.field]}
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              );
            }
          })}
        </div>
        <div className="d-flex justify-content-center">
          <button
            className="btn btn-primary mb-3"
            type="submit"
            value={adjustments.field}
            onClick={handleSubmit}
          >
            Confirmar Edições
          </button>
        </div>
      </div>

      {/* Snackbar for feedback messages */}
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={snackbarSeverity}
          sx={{ width: '100%' }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </div>
  );
}
