import React, { useEffect, useState, useReducer } from "react";
import { Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, Typography, TextField } from "@mui/material";
import { useOrder } from "../../../hooks/useOrder";
import api from "../../../services/api";
import { useAuth } from "../../../hooks/useAuth";
import { useNavigate } from 'react-router-dom';

export function ReviewOrder() {
  const { order, setOrder, fetchOrder } = useOrder();
  const { user } = useAuth();
  const navigate = useNavigate();

  const [requestLoading, setRequestLoading] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogData, setDialogData] = useState({ title: '', message: '', type: 'error' });
  
  useEffect(() => {
    if (!user) {
      navigate("/sign_in");
    }
    if (order.user_id !== user.id) {
      navigate("/orders");
    }
  }, [user, order, navigate]);

  const [inputValues, setInputValues] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      fullName: order.full_name,
      phoneNumber: order.phone_number,
    }
  );

  const [phoneNumber, setPhoneNumber] = useState(order.phone_number);
  const [errors, setErrors] = useState({});

  const handleOnChange = (e) => {
    const { name, value } = e.target;
    setInputValues({ [name]: value });
  };

  // Função para formatar o número de telefone no estilo brasileiro
  const formatPhoneNumber = (value) => {
    const phone = value.replace(/\D/g, ""); // Remove tudo que não for número
    if (phone.length <= 10) {
      return phone.replace(/(\d{2})(\d{4})(\d{0,4})/, "($1) $2-$3"); // (xx) xxxx-xxxx
    } else {
      return phone.replace(/(\d{2})(\d{5})(\d{0,4})/, "($1) $2-$3"); // (xx) xxxxx-xxxx
    }
  };

  const handlePhoneChange = (e) => {
    const formattedPhone = formatPhoneNumber(e.target.value);
    setPhoneNumber(formattedPhone);
  };

  const updateFields = async () => {
    const {
      fullName,
    } = inputValues;

    const data = {
      order: {
        full_name: fullName,
        phone_number: phoneNumber,
      },
    };

    try {
      const response = await api.put(`orders/${order.identifier}`, data);
      const { errors, success } = response.data;
      setErrors(errors);

      if (success) {
        setOrder(response.data);
        return false;
      } else {
        setDialogData({
          title: "Erro!",
          message: "Confira as informações inseridas",
          type: "error",
        });
        setDialogOpen(true);
        return true;
      }
    } catch (error) {
      setDialogData({
        title: "Erro!",
        message: "Ocorreu um erro ao atualizar os campos.",
        type: "error",
      });
      setDialogOpen(true);
      return true;
    }
  };

  const sendToReview = async () => {
    const errors = await updateFields();
    if (errors) return;
    setRequestLoading(true);

    try {
      const response = await api.patch(`/orders/${order.id}/send_to_review`);
      if (response.data.success) {
        fetchOrder();
      } else {
        setDialogData({
          title: "Erro!",
          message: response.data.errors[0].payment,
          type: "error",
        });
        setDialogOpen(true);
      }
    } catch (error) {
      setDialogData({
        title: "Erro!",
        message: "Falha ao enviar o pedido para revisão.",
        type: "error",
      });
      setDialogOpen(true);
    } finally {
      setRequestLoading(false);
    }
  };

  return (
    <Box sx={{ p: 4 }}>
      <Typography variant="h4" gutterBottom>Revisar Pedido</Typography>

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Nome Completo"
            name="fullName"
            value={inputValues.fullName}
            onChange={handleOnChange}
            error={Boolean(errors.full_name)}
            helperText={errors.full_name}
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Telefone"
            name="phoneNumber"
            value={phoneNumber}
            onChange={handlePhoneChange}
            error={Boolean(errors.phone_number)}
            helperText={errors.phone_number || "Digite no formato (11) 99999-9999"}
          />
        </Grid>

        <Grid item xs={12}>
          <Button variant="contained" color="primary" onClick={sendToReview}>
            Enviar para Aprovação
          </Button>
        </Grid>
      </Grid>

      {requestLoading && (
        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
          <CircularProgress />
        </Box>
      )}

      <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)}>
        <DialogTitle>{dialogData.title}</DialogTitle>
        <DialogContent>
          <DialogContentText>{dialogData.message}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDialogOpen(false)}>Fechar</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
