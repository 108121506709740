import React, { useState, useEffect } from 'react';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { TextField, Box, Typography, Alert, Button } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import api from "./../../services/api";
import { useNavigate } from 'react-router-dom';

const ResetPasswordSchema = Yup.object().shape({
  password: Yup.string()
    .min(6, 'A senha deve ter no mínimo 6 caracteres')
    .required('Senha é obrigatória'),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref('password'), null], 'As senhas não coincidem')
    .required('Confirmação de senha é obrigatória'),
});

export function ResetPassword() {
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [token, setToken] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    setToken(urlParams.get('token'));
  }, []);

  const handlePasswordReset = async (values, { setSubmitting }) => {
    setLoading(true);
    setError('');
    setMessage('');

    try {
      // Usando o `api` em vez do `fetch`
      const response = await api.put('/passwords', { token, password: values.password });
      
      setMessage(response.data.message); // Exibe a mensagem de sucesso
    } catch (err) {
      if (err.response) {
        setError(err.response.data.error || 'Erro ao redefinir a senha');
      } else {
        setError('Erro ao redefinir a senha');
      }
      console.error(err);
    }

    setLoading(false);
    setSubmitting(false);
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      minHeight="100vh"
      px={3}
    >
      <Typography variant="h4" gutterBottom>
        Redefinir Senha
      </Typography>
      <Typography variant="body2" mb={2}>
        Defina uma nova senha para a sua conta.
      </Typography>

      {message && <Alert severity="success" sx={{ mb: 2 }}>{message}</Alert>}
      {error && <Alert severity="error" sx={{ mb: 2 }}>{error}</Alert>}

      <Formik
        initialValues={{ password: '', confirmPassword: '' }}
        validationSchema={ResetPasswordSchema}
        onSubmit={handlePasswordReset}
      >
        {({ errors, touched }) => (
          <Form>
            <Box display="flex" flexDirection="column" alignItems="center" mb={2}>
              <Field
                as={TextField}
                name="password"
                label="Nova Senha"
                type="password"
                variant="outlined"
                fullWidth
                error={touched.password && Boolean(errors.password)}
                helperText={touched.password && errors.password}
                sx={{ width: '300px', mb: 2 }}
              />
              <Field
                as={TextField}
                name="confirmPassword"
                label="Confirmar Senha"
                type="password"
                variant="outlined"
                fullWidth
                error={touched.confirmPassword && Boolean(errors.confirmPassword)}
                helperText={touched.confirmPassword && errors.confirmPassword}
                sx={{ width: '300px', mb: 2 }}
              />
              <LoadingButton
                type="submit"
                variant="contained"
                color="secondary"
                loading={loading}
                fullWidth
                sx={{ width: '300px', py: 1 }}
              >
                Redefinir Senha
              </LoadingButton>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                onClick={() => {
                  navigate(`/`)
                }}
                loading={loading}
                fullWidth
                sx={{ width: '300px', py: 1, mt: 1 }}
              >
                Voltar para home
              </Button>
            </Box>
          </Form>
        )}
      </Formik>
    </Box>
  );
}

export default ResetPassword;
