import { useNavigate } from 'react-router-dom';
import { Box, Grid, Typography, Button } from "@mui/material";
import { MainLogo } from "../../components/MainLogo";

export function Congratulations() {
  const navigate = useNavigate();

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', padding: 3 }}>
      <Grid container spacing={3} justifyContent="center">
        
        {/* Logo */}
        <Grid item xs={12} textAlign="center">
          <MainLogo />
        </Grid>
        
        {/* Título de Sucesso */}
        <Grid item xs={12}>
          <Typography variant="h3" color="error" textAlign="center" sx={{ marginTop: 5 }}>
            Pronto! Agora é só aguardar.
          </Typography>
        </Grid>
        
        {/* Mensagem e Email do Usuário */}
        <Grid item xs={12} textAlign="center">
          <Typography variant="body1" color="textPrimary" sx={{ marginTop: 2 }}>
            Seu pedido foi enviado para análise. O prazo é de 72h úteis, mas quase sempre conseguimos aprovar rapidinho!
          </Typography>
        </Grid>
        
        {/* Botão para "Meus Pedidos" */}
        <Grid item xs={12} textAlign="center" sx={{ marginTop: 4 }}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => navigate(`/orders`)}
            endIcon={<i className="fas fa-arrow-right"></i>}
          >
            Meus Pedidos
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
}
