import React, { useContext, useState, useEffect } from 'react';
import {  FormatQuote, AccountCircle, LocalMovies, Event, SportsSoccer, TheaterComedy } from '@mui/icons-material';
import { ThemeProvider } from '@mui/material/styles';
import MenuIcon from '@mui/icons-material/Menu';
import theme from '../../theme'; // Importar o tema que você criou
import { Box, Typography, Button, Drawer, IconButton, List, ListItem, ListItemText, Grid, Container } from "@mui/material";
import { MainLogo } from "../../components/MainLogo";
import FooterImage from "../../assets/images/Group-929.webp";
import CoverImage from "../../assets/images/BG-p-1080.png";
import { AuthContext } from "../../contexts/AuthContext";
import { Dialog, DialogActions, DialogContent, DialogTitle, DialogContentText } from '@mui/material';
import { useNavigate } from 'react-router-dom';

export function Home() {
  const { user, isAuthenticated, signOutUser } = useContext(AuthContext);
  const navigate = useNavigate();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [redirectUrl, setRedirectUrl] = useState('');
  const [drawerOpen, setDrawerOpen] = useState(false);
  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setDrawerOpen(open);
  };

  const handleNewOrder = () => {
    setDialogOpen(false);
    navigate(orderUrl);
  };  

  useEffect(() => {
    if (redirectUrl) {
      window.location.href = redirectUrl;
    }
  }, [redirectUrl]);
  
  
  const handleContinueOrder = () => {
    setDialogOpen(false);
    navigate('/orders');
  };

  const handleOrderClick = () => {
    if (isAuthenticated()) {
      setDialogOpen(true);
    } else {
      navigate(orderUrl);
    }
  };
  
  const orderUrl = process.env.NODE_ENV === 'development' ? '/cde/initializeOrder?' : '/cde/initializeOrder?';
  
  return (
    <ThemeProvider theme={theme}>
      {/* Box de fundo que ocupa a largura total */}
      <Box
        sx={{
          backgroundImage: `url(${CoverImage})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          minHeight: '100vh',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-start',
          alignItems: 'center',
          color: theme.palette.text.primary,
          textAlign: 'center',
          py: 8,
        }}
      >
      {/* Cabeçalho com logo e botões de autenticação */}
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          right: 0,
          display: 'flex',
          alignItems: 'center',
          px: 3,
          py: 0, // Remova qualquer espaçamento adicional
        }}
      >
        {/* Botões de Autenticação no desktop e Menu no mobile */}
        <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
          {isAuthenticated() ? (
            <>
              <Button variant="text" color="secondary" href="/orders">
                Meus Pedidos
              </Button>
              <Button variant="text" color="secondary" onClick={signOutUser}>
                Sair
              </Button>
            </>
          ) : (
            <Button variant="text" color="secondary" href="/sign_in">
              Login
            </Button>
          )}
        </Box>

        {/* Ícone de Menu para mobile */}
        <IconButton
          sx={{ display: { xs: 'block', md: 'none' } }}
          color="inherit"
          onClick={toggleDrawer(true)}
        >
          <MenuIcon />
        </IconButton>

        {/* Drawer para o menu mobile */}
        <Drawer
          anchor="right"
          open={drawerOpen}
          onClose={toggleDrawer(false)}
        >
          <Box
            sx={{ width: 250 }}
            role="presentation"
            onClick={toggleDrawer(false)}
            onKeyDown={toggleDrawer(false)}
          >
            <List>
              {isAuthenticated() ? (
                <>
                  <ListItem button component="a" href="/orders">
                    <ListItemText secondary="Meus Pedidos" />
                  </ListItem>
                  <ListItem button onClick={signOutUser}>
                    <ListItemText secondary="Sair" />
                  </ListItem>
                </>
              ) : (
                <ListItem button component="a" href="/sign_in">
                  <ListItemText secondary="Login" />
                </ListItem>
              )}
            </List>
          </Box>
        </Drawer>
      </Box>
        <MainLogo />
        <Typography variant="h1" mt={3}>Solicite a sua Carteira de Estudante</Typography>
        <Typography variant="h4" mt={2}>
          Garanta sua meia-entrada para cinemas, shows <br />
          e muito mais em apenas 5 minutos.
        </Typography>

        {/* Imagem entre os textos */}
        <Box
          component="img"
          src="https://estudantecc.sfo3.cdn.digitaloceanspaces.com/carteirinhahome.webp"
          alt="Modelo Carteirinha"
          sx={{
            width: '90%',
            maxWidth: 400,
            mt: 3,
            mb: 3,
            borderRadius: 2,
            boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.2)',
            animation: 'float 3s ease-in-out infinite', // Oscilação vertical suave
            position: 'relative',
            overflow: 'hidden',
            '&:hover': {
              transform: 'scale(1.05)', // Leve zoom no hover
              boxShadow: '0px 8px 30px rgba(0, 0, 0, 0.3)',
            },
            '&:before': {
              content: '""',
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              borderRadius: 2,
              border: '2px solid transparent',
              backgroundImage: 'linear-gradient(45deg, rgba(255,255,255,0), rgba(255,255,255,0.5), rgba(255,255,255,0))',
              animation: 'glow 4s linear infinite', // Brilho contínuo ao redor da borda
            },
          }}
        />

        {/* Keyframes para animações */}
        <style>
          {`
            @keyframes float {
              0% { transform: translateY(0); }
              50% { transform: translateY(-10px); }
              100% { transform: translateY(0); }
            }

            @keyframes glow {
              0% { opacity: 0.7; transform: scale(1); }
              50% { opacity: 1; transform: scale(1.05); }
              100% { opacity: 0.7; transform: scale(1); }
            }
          `}
        </style>

        {/* Botão para iniciar o pedido */}
        <Button
          variant="contained"
          color="secondary"
          onClick={handleOrderClick}
          sx={{
            mt: 2,
            mb: 2,
            padding: '12px 24px',
            fontSize: '1.25rem',
            minWidth: '200px',
          }}
        >
          Solicitar Carteirinha
        </Button>

        <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)}>
          <DialogTitle>Continuar Pedido</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Você já tem um pedido em andamento. Deseja continuar com ele ou iniciar um novo?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleNewOrder} color="secondary">
              Novo Pedido
            </Button>
            <Button onClick={handleContinueOrder} color="secondary">
              Continuar Pedido
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
        <Container>
          {/* Seções de Etapas */}
          <Box textAlign="center" mt={5} mb={5}>
            <Typography variant="h2">Mais Vantagens em menos tempo</Typography>
            <Typography variant="body2" mt={1}>
            Estamos a apenas quatro passos para você começar a aproveitar todas as vantagens de ser um estudante.
            </Typography>
          </Box>
          <Grid container spacing={2} justifyContent="center" alignItems="center">
            {[
              { title: 'Escolha o Modelo', description: 'Diferentes modelos para atender a todas as necessidades e estilos.', number: '1' },
              { title: 'Faça o pagamento', description: 'Cartão de crédito ou PIX. Pagamento seguro e confiável.', number: '2' },
              { title: 'Envie sua Foto e Documentação', description: 'Toda a segurança que você precisa para ter sua carteirinha sempre com você.', number: '3' },
              { title: 'Aguarde a análise', description: 'Após análise e aprovação, você receberá um email.', number: '4' },
            ].map((step, index) => (
              <Grid item xs={12} sm={6} md={3} key={index}>
                <Box 
                  textAlign="center" 
                  p={3} 
                  sx={{ 
                    borderRadius: 2, 
                    bgcolor: theme.palette.background.paper,
                    position: 'relative',
                    border: `1px solid ${theme.palette.secondary.main}`,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    transition: 'transform 0.3s ease, box-shadow 0.3s ease',
                    '&:hover': {
                      transform: 'translateY(-5px)',
                      boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.2)',
                    },
                    '&:hover .progress-bar': {
                      width: '100%',
                      transition: 'width 0.3s ease',
                    }
                  }}
                >
                  <Typography variant="h4" color="secondary" sx={{ fontWeight: 'bold' }}>{`Etapa ${step.number}`}</Typography>
                  <Typography variant="h5" color="secondary" mt={1}>{step.title}</Typography>
                  <Typography variant="body2" mt={1}>{step.description}</Typography>
                  
                  {/* Barra de progresso na borda inferior */}
                  <Box 
                    className="progress-bar"
                    sx={{
                      position: 'absolute',
                      bottom: 0,
                      left: 0,
                      height: '4px',
                      bgcolor: theme.palette.success.main,
                      width: '0%', // Inicia em 0 e aumenta no hover
                      transition: 'width 0.3s ease',
                    }}
                  />
                </Box>
              </Grid>
            ))}
          </Grid>

          {/* Benefícios */}
          <Box textAlign="center" mt={5}>
            <Typography variant="h2">Meia entrada sem complicação</Typography>
            <Typography variant="body2" mt={1}>
              Aceita nos melhores cinemas, eventos culturais e esportivos sem a necessidade de apresentação de comprovação adicional.
            </Typography>
          </Box>

          <Grid container spacing={4} mt={4}>
            {[
              { icon: <LocalMovies fontSize="large" />, title: 'Cinemas', description: 'Pra quem não perde uma estreia nas telonas.' },
              { icon: <Event fontSize="large" />, title: 'Shows e Eventos', description: 'Para não perder o show da sua vida.' },
              { icon: <SportsSoccer fontSize="large" />, title: 'Eventos esportivos', description: 'Futebol, vôlei e muito mais.' },
              { icon: <TheaterComedy fontSize="large" />, title: 'Espetáculos Culturais', description: 'Para quem respira arte e cultura.' },
            ].map((benefit, index) => (
              <Grid item xs={12} sm={6} md={3} key={index}>
                <Box
                  textAlign="center"
                  sx={{
                    bgcolor: theme.palette.background.paper,
                    p: 3,
                    borderRadius: 2,
                    transition: 'transform 0.3s ease, box-shadow 0.3s ease',
                    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
                    '&:hover': {
                      transform: 'scale(1.05) rotate(1deg)',
                      boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.2)',
                      border: `1px solid ${theme.palette.secondary.main}`,
                    },
                    '&:before': {
                      content: '""',
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      width: '100%',
                      height: '100%',
                      borderRadius: 'inherit',
                      border: `2px solid ${theme.palette.secondary.main}`,
                      opacity: 0,
                      transform: 'scale(0.9)',
                      transition: 'opacity 0.3s ease, transform 0.3s ease',
                    },
                    '&:hover:before': {
                      opacity: 1,
                      transform: 'scale(1)',
                    }
                  }}
                >
                  {benefit.icon}
                  <Typography variant="h5" mt={2} color="secondary">{benefit.title}</Typography>
                  <Typography variant="body2">{benefit.description}</Typography>
                </Box>
              </Grid>
            ))}
          </Grid>


          {/* Depoimentos */}
          <Box textAlign="center" mt={5}>
            <Typography variant="h2">Depoimentos</Typography>
            <Typography variant="body2" mt={2}>
              Palavra de quem já usa e confia na carteirinha de estudante
            </Typography>
          </Box>

          <Grid container spacing={4} mt={3}>
            {[
              {
                name: 'Eduardo Panicali',
                course: 'Nutrição - UFRGS',
                feedback: 'Muito bom! Achei muito fácil de comprar e minha carteirinha ficou pronta antes do prazo.',
              },
              {
                name: 'Ana de Sousa',
                course: 'Dermatologia - UFRJ',
                feedback: 'Achei super prático. Além de ser fácil pedir a carteirinha, fiquei confusa com os meus pedidos e me deram suporte rápido, claro e educado. Uso ela em todos os eventos que vou. Muito mais fácil do que ficar andando com comprovante de matrícula.',
              },
              {
                name: 'Camila Nunes',
                course: 'Psicologia - UFMG',
                feedback: 'Amei! Achei ótimo ter mais de um modelo para escolha. Já uso desde o segundo semestre e aceitou em todos os lugares, sem problema nenhum. Adorei!',
              },
            ].map((testimonial, index) => (
              <Grid item xs={12} md={4} key={index}>
                <Box
                  textAlign="center"
                  sx={{
                    bgcolor: theme.palette.background.paper,
                    p: 3,
                    borderRadius: 2,
                    position: 'relative',
                    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
                    transition: 'transform 0.3s ease, box-shadow 0.3s ease',
                    '&:hover': {
                      transform: 'scale(1.05)',
                      boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.2)',
                      border: `1px solid ${theme.palette.secondary.main}`,
                    },
                  }}
                >
                  {/* Ícone de citação */}
                  <FormatQuote sx={{ fontSize: 40, color: theme.palette.secondary.main, mb: 1 }} />

                  {/* Feedback do usuário */}
                  <Typography variant="body1" mb={2}>“{testimonial.feedback}”</Typography>

                  {/* Informações do usuário com ícone de perfil */}
                  <Box display="flex" justifyContent="center" alignItems="center" mt={2}>
                    <AccountCircle sx={{ color: theme.palette.primary.main, mr: 1 }} />
                    <Box textAlign="left">
                      <Typography variant="subtitle2" fontWeight="bold">
                        {testimonial.name}
                      </Typography>
                      <Typography variant="caption">{testimonial.course}</Typography>
                    </Box>
                  </Box>
                </Box>
              </Grid>
            ))}
          </Grid>

          {/* Botão para iniciar o pedido */}
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              mt: 5,
              mb: 2,
            }}
          >
            <Button
              variant="contained"
              color="secondary"
              onClick={handleOrderClick}
              sx={{
                mt: 2,
                mb: 2,
                padding: '12px 24px',
                fontSize: '1.25rem',
                minWidth: '200px',
              }}
            >
              Solicitar Carteirinha
            </Button>

            <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)}>
              <DialogTitle>Continuar Pedido</DialogTitle>
              <DialogContent>
                <DialogContentText>
                  Você já tem um pedido em andamento. Deseja continuar com ele ou iniciar um novo?
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleNewOrder} color="secondary">
                  Novo Pedido
                </Button>
                <Button onClick={handleContinueOrder} color="secondary">
                  Continuar Pedido
                </Button>
              </DialogActions>
            </Dialog>
          </Box>

          {/* Informação legal */}
          <Box textAlign="center" mt={5} mb={3}>
            <Typography variant="h2">Validade garantida por lei</Typography>
            <Typography variant="body2" mt={1}>
              Todas as carteirinhas seguem a Lei 12.933/13 e são aceitas em todo o território nacional.
            </Typography>

            {/* Imagem abaixo do texto */}
            <Box
              component="img"
              src={FooterImage} // Usando a imagem importada diretamente como src
              alt="Validade Garantida"
              sx={{ width: '100%', maxWidth: 300, mt: 3 }}
            />
          </Box>

          {/* Footer */}
          <Box textAlign="center" mt={5} pt={3} sx={{ borderTop: `1px solid ${theme.palette.text.secondary}` }}>
            <Typography variant="body2" mb={2}>© 2024 Todos os direitos reservados.</Typography>

            {/* Botão para Política de Privacidade */}
            <Button
              variant="text"
              color="primary"
              href="/privacy_policy"
            >
              Política de Privacidade
            </Button>

            {/* Botão para Termos de Uso */}
            <Button
              variant="text"
              color="primary"
              href="/terms"
            >
              Termos de Uso
            </Button>

            {/* Botão para Perguntas Frequentes no WhatsApp */}
            <Button
              variant="text"
              color="primary"
              href="https://wa.me/5531996520661"
              target="_blank"
              rel="noopener noreferrer"
            >
              Perguntas Frequentes
            </Button>
          </Box>
        </Container>
    </ThemeProvider>
  );
}

export default Home;

