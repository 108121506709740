import React, { useEffect, useState, useContext } from "react";

// Imports do Material-UI
import {
  Box,
  Grid,
  Typography,
  Button,
  CircularProgress,
  Card,
  CardContent,
  Chip,
  Tooltip,
} from "@mui/material";
import {
  CheckCircle as CheckCircleIcon,
  HourglassEmpty as HourglassEmptyIcon,
  Error as ErrorIcon,
  Warning as WarningIcon,
  Block as BlockIcon,
  Info as InfoIcon,
  ThumbUp as ThumbUpIcon,
  Cancel as CancelIcon,
  Autorenew as AutorenewIcon,
  AssignmentLate as AssignmentLateIcon,
  Payment as PaymentIcon,
  Replay as ReplayIcon,
  Logout as LogoutIcon,
  AssignmentTurnedIn as AssignmentTurnedInIcon,
  Edit as EditIcon,
  Visibility as VisibilityIcon,
  Archive as ArchiveIcon,
  DoneAll as DoneAllIcon,
} from "@mui/icons-material";

// Imports de serviços e utilidades
import api from "../../services/api";
import strftime from "strftime";

// Imports de componentes internos
import { MainLogo } from "../../components/MainLogo";
import { AuthContext } from "../../contexts/AuthContext";

export function Orders() {
  const [myOrders, setMyOrders] = useState([]);
  const [requestLoading, setRequestLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const { signOutUser } = useContext(AuthContext);

  const fetchOrders = async () => {
    try {
      setRequestLoading(true);
      const response = await api.get("/orders");
      setMyOrders(response.data.orders);
    } catch (error) {
      setErrorMessage("Erro ao buscar pedidos. Por favor, tente novamente.");
      console.error("Erro ao buscar pedidos:", error);
    } finally {
      setRequestLoading(false);
    }
  };

  useEffect(() => {
    fetchOrders();
  }, []);

  const kindTranslations = {
    digital: "Digital",
    physical: "Física",
    both: "Física e digital",
  };

  const paymentStatusDisplay = {
    invoice_not_generated: { label: "Não gerado", icon: <BlockIcon color="action" /> },
    pending: { label: "Pendente", icon: <HourglassEmptyIcon color="warning" /> },
    paid: { label: "Pago", icon: <CheckCircleIcon color="success" /> },
    partially_paid: { label: "Parcialmente Pago", icon: <InfoIcon color="info" /> },
    expired: { label: "Expirado", icon: <WarningIcon color="disabled" /> },
    canceled: { label: "Cancelado", icon: <CancelIcon color="error" /> },
    in_protest: { label: "Em Protesto", icon: <AssignmentLateIcon color="error" /> },
    chargeback: { label: "Chargeback", icon: <ReplayIcon color="error" /> },
    refunded: { label: "Reembolsado", icon: <AutorenewIcon color="info" /> },
    in_analysis: { label: "Em Análise", icon: <InfoIcon color="primary" /> },
    externally_paid: { label: "Pago Externamente", icon: <PaymentIcon color="success" /> },
    default: { label: "Status desconhecido", icon: <InfoIcon color="disabled" /> },
  };
  
  const orderStatusDisplay = {
    started: { label: "Iniciado", icon: <BlockIcon color="action" /> },
    design_filled: { label: "Modelo escolhido", icon: <InfoIcon color="disabled" /> },
    photo_uploaded: { label: "Foto enviada", icon: <InfoIcon color="disabled" /> },
    invoice_generated: { label: "Fatura gerada", icon: <WarningIcon color="warning" /> },
    invoice_details_advanced: { label: "Fatura avançada", icon: <WarningIcon color="warning" /> },
    student_details_filled: { label: "Detalhes preenchidos", icon: <ThumbUpIcon color="primary" /> },
    personal_identification_uploaded: { label: "Documento enviado", icon: <ThumbUpIcon color="primary" /> },
    institution_identification_filled: { label: "Comprovante enviado", icon: <ThumbUpIcon color="primary" /> },
    waiting_review: { label: "Em revisão", icon: <HourglassEmptyIcon color="error" /> },
    approved: { label: "Aprovado", icon: <CheckCircleIcon color="success" /> },
    adjustments_requested: { label: "Em ajustes", icon: <EditIcon color="action" /> },
    rejected: { label: "Rejeitado", icon: <CancelIcon color="disabled" /> },
    blocked: { label: "Bloqueado", icon: <BlockIcon color="error" /> },
    default: { label: "Status desconhecido", icon: <InfoIcon color="disabled" /> },
  };

  const handleShelveOrder = async (e, order) => {
    e.preventDefault();
    try {
      setRequestLoading(true);
      await api.put(`/orders/${order.identifier}/shelve_order`, { order: { shelved: true } });
      fetchOrders();
    } catch (error) {
      setErrorMessage("Erro ao arquivar o pedido. Por favor, tente novamente.");
      console.error("Erro ao arquivar pedido:", error);
    } finally {
      setRequestLoading(false);
    }
  };

  const renderOrder = (order) => (
    <Card key={order.identifier} sx={{ marginBottom: 4, boxShadow: 3, position: "relative" }}>
      <CardContent>
        <Grid container spacing={2}>
          {/* Ícone de Concluído para pedidos aprovados */}
          {order.approved_at && (
            <Grid item xs={12} md={2} sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
              <Tooltip title="Pedido concluído">
                <DoneAllIcon color="success" sx={{ fontSize: 80 }} />
              </Tooltip>
            </Grid>
          )}

          {/* Detalhes do Pedido */}
          <Grid item xs={12} md={order.approved_at ? 5 : 7}>
            <Typography variant="body2" color="textSecondary">
              Tipo: {kindTranslations[order.kind]}
            </Typography>
            <Box sx={{ display: "flex", alignItems: "center", mt: 1 }}>
              <Typography variant="body2" sx={{ mr: 1 }}>
                <strong>Etapa:</strong>
              </Typography>
              <Chip
                label={orderStatusDisplay[order.status]?.label || "Status desconhecido"}
                icon={orderStatusDisplay[order.status]?.icon || <ErrorIcon color="error" />}
                color="default"
                size="small"
              />
            </Box>
            <Typography variant="body2">
              <strong>Nome:</strong> {order.full_name}
            </Typography>
            <Typography variant="body2">
              <strong>Criado em:</strong> {strftime("%d/%m/%Y - %H:%M", new Date(order.created_at))}
            </Typography>
            <Box sx={{ display: "flex", alignItems: "center", mt: 1 }}>
              <Typography variant="body2" sx={{ mr: 1 }}>
                <strong>Pagamento:</strong>
              </Typography>
              <Chip
                label={paymentStatusDisplay[order.invoice_status]?.label || "Fatura não gerada"}
                icon={paymentStatusDisplay[order.invoice_status]?.icon || <ErrorIcon color="error" />}
                color="default"
                size="small"
              />
            </Box>
          </Grid>

          {/* Ações */}
          <Grid item xs={12} md={order.approved_at ? 5 : 3} sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
            {order.approved_at ? (
              <Button
                variant="contained"
                startIcon={<AssignmentTurnedInIcon />}
                href={`orders/${order.identifier}`}
                fullWidth
                sx={{ maxWidth: 200 }}
              >
                Abrir carteirinha
              </Button>
            ) : order.status === "adjustments_requested" ? (
              <Button
                variant="contained"
                startIcon={<EditIcon />}
                href={`orders/${order.identifier}`}
                fullWidth
                sx={{ maxWidth: 200 }}
              >
                Realizar ajustes
              </Button>
            ) : (
              <Button
                variant="contained"
                startIcon={<VisibilityIcon />}
                href={`orders/${order.identifier}`}
                fullWidth
                sx={{ maxWidth: 200 }}
              >
                Continuar pedido
              </Button>
            )}
            {order.invoice_url && order.invoice_status === "pending" && (
              <Button
                variant="outlined"
                startIcon={<VisibilityIcon />}
                href={order.invoice_url}
                fullWidth
                target="_blank"
                sx={{ maxWidth: 200 }}
              >
                Visualizar fatura
              </Button>
            )}
            {!order.shelved && (
              <Button
                variant="text"
                color="error"
                startIcon={<ArchiveIcon />}
                onClick={(e) => handleShelveOrder(e, order)}
                fullWidth
                sx={{ maxWidth: 200 }}
              >
                Arquivar pedido
              </Button>
            )}
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );

  return (
    <Box sx={{ padding: 3 }}>
      <Box sx={{ textAlign: "center", marginBottom: 5 }}>
        <MainLogo />
      </Box>
      <Typography variant="h4" textAlign="center" sx={{ marginBottom: 5 }}>
        Meus pedidos
      </Typography>

      <Box
        textAlign="center"
        sx={{ display: "flex", justifyContent: "center", alignItems: "center", marginBottom: 4, gap: 2 }}
      >
        <Button variant="outlined" color="error" href="https://estudante.cc/cde/initializeOrder?">
          Novo Pedido
        </Button>
        <Button variant="outlined" color="error" href="/shelved_orders">
          Pedidos Arquivados
        </Button>
        <Button variant="outlined" color="error" onClick={signOutUser} startIcon={<LogoutIcon />}>
          Sair
        </Button>
      </Box>

      {errorMessage && (
        <Box sx={{ textAlign: "center", marginBottom: 4, color: "red" }}>
          <Typography variant="body1">{errorMessage}</Typography>
        </Box>
      )}

      {requestLoading ? (
        <Box sx={{ display: "flex", justifyContent: "center", marginTop: 4 }}>
          <CircularProgress />
        </Box>
      ) : (
        <Box>
          {myOrders
            .filter((order) => order.approved_at && !order.shelved)
            .map((order) => renderOrder(order))}
          {myOrders
            .filter((order) => !order.approved_at && !order.shelved)
            .map((order) => renderOrder(order))}
        </Box>
      )}
    </Box>
  );
}
