import React, { useState } from 'react';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { TextField, Button, Box, Typography, Alert } from '@mui/material';
import EmailIcon from '@mui/icons-material/Email';
import { LoadingButton } from '@mui/lab';
import api from "./../../services/api"; // Importando a instância Axios configurada
import { useNavigate } from 'react-router-dom';

const ForgotPasswordSchema = Yup.object().shape({
  email: Yup.string().email('E-mail inválido').required('E-mail é obrigatório'),
});

export function ForgotPassword() {
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const requestPasswordReset = async (values, { setSubmitting }) => {
    setLoading(true);
    setError('');
    setMessage('');

    try {
      // Usando o `api` em vez do `fetch`
      const response = await api.post('/passwords', { email: values.email });
      
      setMessage(response.data.message); // Exibindo a mensagem de sucesso
    } catch (err) {
      if (err.response) {
        setError(err.response.data.error || 'Erro ao solicitar recuperação de senha');
      } else {
        setError('Erro ao solicitar recuperação de senha');
      }
      console.error(err);
    }

    setLoading(false);
    setSubmitting(false);
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      minHeight="100vh"
      px={3}
    >
      <Typography variant="h4" gutterBottom>
        Recuperação de Senha
      </Typography>

      <Typography variant="body2" mb={2}>
        Insira seu e-mail para receber as instruções de recuperação de senha.
      </Typography>

      {message && <Alert severity="success" sx={{ mb: 2 }}>{message}</Alert>}
      {error && <Alert severity="error" sx={{ mb: 2 }}>{error}</Alert>}

      <Formik
        initialValues={{ email: '' }}
        validationSchema={ForgotPasswordSchema}
        onSubmit={requestPasswordReset}
      >
        {({ errors, touched }) => (
          <Form>
            <Box display="flex" flexDirection="column" alignItems="center" mb={2}>
              <Box display="flex" alignItems="center" mb={2}>
                <EmailIcon sx={{ mr: 1, color: 'secondary.main' }} />
                <Field
                  as={TextField}
                  name="email"
                  label="E-mail"
                  variant="outlined"
                  fullWidth
                  error={touched.email && Boolean(errors.email)}
                  helperText={touched.email && errors.email}
                  sx={{ width: '300px' }}
                />
              </Box>

              <LoadingButton
                type="submit"
                variant="contained"
                color="secondary"
                loading={loading}
                fullWidth
                sx={{ width: '300px', py: 1 }}
              >
                Enviar
              </LoadingButton>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                onClick={() => {
                  navigate(`/`)
                }}
                loading={loading}
                fullWidth
                sx={{ width: '300px', py: 1, mt: 1 }}
              >
                Voltar para home
              </Button>
            </Box>
          </Form>
        )}
      </Formik>
    </Box>
  );
}

export default ForgotPassword;
