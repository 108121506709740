import { Box, Stepper, Step, StepLabel } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useContext, useEffect, useState } from "react";
import { OrderContext } from "../../contexts/OrderContext"; // Importe o contexto da order

// Array com os steps da order em inglês (mantém a lógica em inglês)
const steps = [
  "started",
  "design_filled",
  "invoice_generated",
  "invoice_details_advanced",
  "student_details_filled",
  "photo_uploaded",
  "personal_identification_uploaded",
  "institution_identification_filled",
];

// Mapeamento de steps em inglês para português (para exibição ao usuário)
const stepsInPortuguese = {
  started: "Escolher Modelo",
  design_filled: "Criar Usuário",
  invoice_generated: "Pagamento",
  invoice_details_advanced: "Dados Pessoais",
  student_details_filled: "Foto",
  photo_uploaded: "Documentos Pessoais",
  personal_identification_uploaded: "Dados da Instituição",
  institution_identification_filled: "Revisão",
};

export function SideBarLeft() {
  const theme = useTheme();
  const { order } = useContext(OrderContext); // Acessa o contexto para obter a order
  const [activeStep, setActiveStep] = useState(0); // O step ativo inicial é 0 (início)

  // Atualiza o activeStep com base no status da ordem
  useEffect(() => {
    if (order?.status) {
      const currentStepIndex = steps.indexOf(order.status); // Mapeia o status da order para o índice do step
      if (currentStepIndex !== -1) {
        setActiveStep(currentStepIndex); // Atualiza o activeStep
      }
    }
  }, [order]); // Atualiza quando o status da order mudar

  return (
    <Box
      sx={{
        display: { xs: "none", md: "block" },
        backgroundColor: theme.palette.background.paper,
        padding: 2,
        height: "100%",
        boxShadow: 3,
        color: theme.palette.text.primary,
      }}
    >
      {/* Stepper em modo vertical */}
      <Stepper activeStep={activeStep} orientation="vertical">
        {steps.map((step, index) => (
          <Step key={step}>
            <StepLabel
              sx={{
                "& .MuiStepLabel-label": {
                  color: theme.palette.text.secondary,
                  fontFamily: theme.typography.fontFamily,
                  "&.Mui-active": {
                    color: theme.palette.primary.main,
                  },
                  "&.Mui-completed": {
                    color: theme.palette.textconclued.main,
                  },
                },
              }}
            >
              {stepsInPortuguese[step]} {/* Exibe o nome em português */}
            </StepLabel>
          </Step>
        ))}
      </Stepper>
    </Box>
  );
}
