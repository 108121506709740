import Routes from "./routes";
import { AuthContextProvider } from "./contexts/AuthContext";
import theme from './theme'; // Importa o tema que você criou
import { ThemeProvider } from '@mui/material/styles'; // Importa o ThemeProvider do MUI
import CssBaseline from '@mui/material/CssBaseline'; // Importa o CssBaseline do MUI

function App() {
  return (
    <div className="App">
      <AuthContextProvider>
        <ThemeProvider theme={theme}> {/* Aplica o tema em toda a aplicação */}
          <CssBaseline /> {/* Garante que o fundo escuro e as estilizações globais sejam aplicadas */}
          <Routes></Routes>
        </ThemeProvider>
      </AuthContextProvider>
    </div>
  );
}

export default App;
