import { Box, Typography, Grid, Button } from "@mui/material";
import { useNavigate } from 'react-router-dom';

export function RejectRequest() {
  const navigate = useNavigate();

  return (
    <Box sx={{ padding: 5 }}>
      <Grid container justifyContent="center" alignItems="center" spacing={3}>
        <Grid item xs={12}>
          <Typography variant="h4" color="error" align="center">
            Pedido bloqueado!
          </Typography>
        </Grid>
        
        <Grid item xs={12} display="flex" justifyContent="center">
        </Grid>

        <Grid item xs={12}>
          <Typography variant="body1" color="textPrimary" align="center">
            O seu pedido foi bloqueado, por favor, entre em contato através do email:
            <Typography component="span" color="primary"> sac@estudante.cc </Typography> 
            para mais informações.
          </Typography>
        </Grid>

        <Grid item xs={12} display="flex" justifyContent="center">
          <Button
            variant="contained"
            color="primary"
            onClick={() => navigate(`/orders`)}
            endIcon={<i className="fas fa-arrow-right"></i>}
          >
            Meus Pedidos
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
}
