import { useState, useEffect } from "react";
import { TextField, Button, Box, Typography, Grid } from "@mui/material";
import { useAuth } from '../../hooks/useAuth';
import { useNavigate } from 'react-router-dom';
import { MainLogo } from "../../components/MainLogo";

export function SignIn() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const { user, signInUser } = useAuth();
  const navigate = useNavigate();

  const handleLoginSubmit = (e) => {
    e.preventDefault();
    signInUser(email, password);
  };

  useEffect(() => {
    if (user) {
      navigate(`/orders`);
    }
  }, [user, navigate]);

  return (
    <Box 
      sx={{ 
        display: 'flex', 
        justifyContent: 'center', 
        alignItems: 'center', 
        height: '100vh',
        backgroundColor: 'background.default'
      }}
    >
      <Box 
        sx={{ 
          width: '100%', 
          maxWidth: 400, 
          padding: 4, 
          backgroundColor: 'background.paper', 
          borderRadius: 2, 
          boxShadow: 3 
        }}
      >
        <Grid container justifyContent="center" mb={3}>
          <MainLogo />
        </Grid>
        <Typography variant="h6" mb={2}>
          Para acessar seus pedidos, faça o login abaixo:
        </Typography>
        <form onSubmit={handleLoginSubmit}>
          <TextField
            fullWidth
            label="Email"
            type="email"
            variant="outlined"
            margin="normal"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            sx={{ mb: 2 }}
          />
          <TextField
            fullWidth
            label="Senha"
            type="password"
            variant="outlined"
            margin="normal"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            sx={{ mb: 2 }}
          />
          <Button 
            type="submit" 
            fullWidth 
            variant="contained" 
            color="primary" 
            sx={{ mb: 2 }}
          >
            Entrar
          </Button>
          <Button 
            fullWidth 
            variant="outlined" 
            color="secondary" 
            onClick={() => navigate('/forgot_password')}
          >
            Recuperar senha
          </Button>
        </form>
      </Box>
    </Box>
  );
}
