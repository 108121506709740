import React, { useRef, useState } from 'react';
import {
  Box,
  Button,
  CircularProgress,
  Typography,
  RadioGroup,
  FormControlLabel,
  Radio,
  TextField,
  Checkbox,
  Slider,
} from '@mui/material';
import {
  WbSunny,
  ArrowForward,
  Brightness2,
  Schedule,
  AllInclusive,
  CloudUpload,
} from '@mui/icons-material';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { useOrder } from '../../../hooks/useOrder';
import api from '../../../services/api';
import { useAuth } from '../../../hooks/useAuth';

export function InstitutionIdentification(props) {
  const { order, setOrder } = useOrder();
  const { user } = useAuth();
  const [requestLoading, setRequestLoading] = useState(false);
  const fileInputRef = useRef(null);

  const validationSchema = Yup.object().shape({
    registrationNumber: Yup.string().when(
      'hasNotInformedRegistrationNumber',
      (hasNotInformed, schema) => {
        return hasNotInformed
          ? schema
          : schema.required('Número de matrícula é obrigatório.');
      }
    ),
    hasNotInformedRegistrationNumber: Yup.boolean(),
    institutionName: Yup.string().required('Nome da instituição é obrigatório.'),
    period: Yup.number()
      .required('Período é obrigatório.')
      .min(1, 'O período mínimo é 1.')
      .max(10, 'O período máximo é 10.'),
    time: Yup.string().required('Turno é obrigatório.'),
    institutionStatementPhoto: Yup.mixed()
      .required('Documento é obrigatório.')
      .test('fileSize', 'O arquivo é muito grande. Máximo 5MB.', (value) => {
        if (!value) return true; // Skip test if no file is provided
        return value.size <= 5 * 1024 * 1024; // 5MB
      })
      .test(
        'fileFormat',
        'Formato de arquivo não suportado. São aceitos: .pdf, .jpg, .png',
        (value) => {
          if (!value) return true; // Skip test if no file is provided
          return ['application/pdf', 'image/jpeg', 'image/png'].includes(value.type);
        }
      ),
  });

  const initialValues = {
    registrationNumber: '',
    hasNotInformedRegistrationNumber: false,
    institutionName: '',
    period: 1,
    time: '',
    institutionStatementPhoto: null,
  };

  const handleSubmit = async (values, { setSubmitting, setErrors }) => {
    const dataObject = new FormData();
    dataObject.append(
      'order[card_institution_statement_photo]',
      values.institutionStatementPhoto
    );
    dataObject.append('order[institution_name]', values.institutionName);
    dataObject.append(
      'order[has_not_informed_registration_number]',
      values.hasNotInformedRegistrationNumber
    );
    dataObject.append('order[registration_number]', values.registrationNumber);
    dataObject.append('order[time]', values.time);
    dataObject.append('order[period]', values.period);

    setRequestLoading(true);

    try {
      const response = await api.put(`/orders/${order.identifier}`, dataObject);
      setOrder(response.data);

      if (response.data.errors) {
        setErrors(response.data.errors);
      } else {
        // Success: You can redirect or display a success message here.
      }
    } catch (error) {
      setErrors({ api: 'Falha ao enviar dados. Por favor, tente novamente.' });
    } finally {
      setRequestLoading(false);
      setSubmitting(false);
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({
        values,
        errors,
        touched,
        setFieldValue,
        setFieldTouched,
        isSubmitting,
      }) => (
        <Form>
          <Box>
            <Typography variant="h6">Coloque seus dados de curso:</Typography>

            <Box>
              <Field
                as={TextField}
                label="Número da Matrícula"
                fullWidth
                name="registrationNumber"
                disabled={values.hasNotInformedRegistrationNumber}
                error={
                  touched.registrationNumber && Boolean(errors.registrationNumber)
                }
                helperText={touched.registrationNumber && errors.registrationNumber}
                sx={{ mb: 2 }}
              />
            </Box>

            <FormControlLabel
              control={
                <Field
                  as={Checkbox}
                  name="hasNotInformedRegistrationNumber"
                  type="checkbox"
                />
              }
              label="Não quero informar o número de matrícula"
            />

            <Box>
              <Field
                as={TextField}
                label="Instituição de Ensino"
                fullWidth
                name="institutionName"
                error={
                  touched.institutionName && Boolean(errors.institutionName)
                }
                helperText={touched.institutionName && errors.institutionName}
                sx={{ mb: 2 }}
              />
            </Box>

            <Box>
              <Typography id="slider-label" gutterBottom>
                Período: {values.period}
              </Typography>
              <Slider
                aria-labelledby="slider-label"
                value={values.period}
                onChange={(event, newValue) =>
                  setFieldValue('period', newValue)
                }
                step={1}
                marks
                min={1}
                max={10}
                valueLabelDisplay="auto"
                sx={{ mb: 2 }}
              />
              {touched.period && errors.period && (
                <Typography color="error">{errors.period}</Typography>
              )}
            </Box>

            <Typography variant="body1" gutterBottom>
              Turno:
            </Typography>
            <Field name="time">
              {({ field }) => (
                <RadioGroup
                  {...field}
                  row
                  onChange={(event) => setFieldValue('time', event.target.value)}
                >
                  <FormControlLabel
                    value="morning"
                    control={<Radio />}
                    label={
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <WbSunny />
                        <Typography sx={{ ml: 1 }}>Matutino</Typography>
                      </Box>
                    }
                  />
                  <FormControlLabel
                    value="afternoon"
                    control={<Radio />}
                    label={
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Schedule />
                        <Typography sx={{ ml: 1 }}>Vespertino</Typography>
                      </Box>
                    }
                  />
                  <FormControlLabel
                    value="night"
                    control={<Radio />}
                    label={
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Brightness2 />
                        <Typography sx={{ ml: 1 }}>Noturno</Typography>
                      </Box>
                    }
                  />
                  <FormControlLabel
                    value="integral"
                    control={<Radio />}
                    label={
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <AllInclusive />
                        <Typography sx={{ ml: 1 }}>Integral</Typography>
                      </Box>
                    }
                  />
                </RadioGroup>
              )}
            </Field>
            {touched.time && errors.time && (
              <Typography color="error">{errors.time}</Typography>
            )}

            <Box sx={{ mt: 2 }}>
              <Typography variant="h6">
                Faça upload de um documento que comprove o vínculo com a sua
                instituição de ensino.
              </Typography>
              <Box sx={{ textAlign: 'center', mt: 2 }}>
                {!values.institutionStatementPhoto ? (
                  <>
                    <Box
                      sx={{ cursor: 'pointer', textAlign: 'center', mb: 2 }}
                      onClick={() => fileInputRef.current.click()}
                    >
                      <CloudUpload sx={{ fontSize: 100 }} color="primary" />
                    </Box>
                    <Button
                      variant="outlined"
                      onClick={() => fileInputRef.current.click()}
                    >
                      Fazer Upload
                    </Button>
                    <input
                      ref={fileInputRef}
                      type="file"
                      style={{ display: 'none' }}
                      accept=".pdf,.jpg,.png"
                      onChange={(event) => {
                        const file = event.currentTarget.files[0];
                        setFieldValue('institutionStatementPhoto', file);
                        setFieldTouched('institutionStatementPhoto', true);
                      }}
                    />
                    {touched.institutionStatementPhoto &&
                      errors.institutionStatementPhoto && (
                        <Typography color="error" sx={{ mt: 1 }}>
                          {errors.institutionStatementPhoto}
                        </Typography>
                      )}
                  </>
                ) : (
                  <Box textAlign="center">
                    <Typography variant="h6" color="success.main">
                      Arquivo anexado com sucesso.
                    </Typography>
                    <Button
                      variant="contained"
                      color="warning"
                      onClick={() =>
                        setFieldValue('institutionStatementPhoto', null)
                      }
                    >
                      Trocar Documento
                    </Button>
                  </Box>
                )}
              </Box>
            </Box>

            <Box textAlign="center" mt={3}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={isSubmitting || requestLoading}
                endIcon={<ArrowForward />}
              >
                Enviar Dados
              </Button>
            </Box>
            {(isSubmitting || requestLoading) && (
              <Box textAlign="center" mt={2}>
                <CircularProgress />
              </Box>
            )}
            {errors.api && (
              <Typography color="error" sx={{ mt: 2, textAlign: 'center' }}>
                {errors.api}
              </Typography>
            )}
          </Box>
        </Form>
      )}
    </Formik>
  );
}
