import { useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import { useAuth } from "../../hooks/useAuth";
import api from "../../services/api";
import { useParams } from "react-router";

export function InitializeOrder() {
  const { isAuthenticated } = useAuth();
  const navigate = useNavigate();
  const { site } = useParams();

  const createNewOrder = async () => {
    const orderData = {};

    if (isAuthenticated()) {
      const response = await api.post("orders/create_with_user", orderData);
      navigate(`/orders/${response.data.identifier}`);
    } else {
      const response = await api.post("orders", orderData);
      navigate(`/orders/${response.data.identifier}`);
    }
  };

  useEffect(() => {
    window.localStorage.setItem('@app/site', site);
    createNewOrder();
  }, [site]);

  return (
    <>
      { }
    </>
  );
}
