import React, { useEffect, useState } from 'react';
import axios from 'axios';


export function AdminOrders() {
    const [orders, setOrders] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        fetchOrders();
    }, []);

    const fetchOrders = async () => {
        try {
            const response = await axios.get('/api/v1/admin/orders');
            setOrders(response.data);
            setLoading(false);
        } catch (err) {
            setError(err);
            setLoading(false);
        }
    };

    const handleApprove = async (id) => {
        try {
            await axios.post(`/api/v1/admin/orders/${id}/approve_via_api`);
            fetchOrders();
        } catch (err) {
            setError(err);
        }
    };

    const handleReject = async (id) => {
        try {
            await axios.post(`/api/v1/admin/orders/${id}/reject_via_api`);
            fetchOrders();
        } catch (err) {
            setError(err);
        }
    };

    const handleExempt = async (id) => {
        try {
            await axios.post(`/api/v1/admin/orders/${id}/exempt`);
            fetchOrders();
        } catch (err) {
            setError(err);
        }
    };

    if (loading) return <div>Loading...</div>;
    if (error) return <div>Error: {error.message}</div>;

    return (
        <div>
            <h1>Orders</h1>
            <ul>
                {orders.map(order => (
                    <li key={order.id}>
                        {order.name} - {order.status}
                        <button onClick={() => handleApprove(order.id)}>Approve</button>
                        <button onClick={() => handleReject(order.id)}>Reject</button>
                        <button onClick={() => handleExempt(order.id)}>Exempt</button>
                    </li>
                ))}
            </ul>
        </div>
    );
};
