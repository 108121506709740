import { useState, useEffect } from "react";
import { useAuth } from '../../hooks/useAuth';
import { useNavigate } from 'react-router-dom';
import { TextField, Button, Box, Typography, Paper } from "@mui/material";

export function SignUp() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const { user, signUpUser } = useAuth();
  const navigate = useNavigate();

  const handleSignUpSubmit = async (e) => {
    e.preventDefault();
    await signUpUser(email, password);
  };

  useEffect(() => {
    if (user) {
      navigate(`/orders`);
    }
  }, [user, navigate]);

  return (
    <Box 
      sx={{ 
        display: 'flex', 
        justifyContent: 'center', 
        alignItems: 'center', 
        height: '100vh', 
        backgroundColor: 'background.default' 
      }}
    >
      <Paper elevation={3} sx={{ padding: 4, maxWidth: 400, width: '100%' }}>
        <Typography variant="h4" textAlign="center" gutterBottom>
          Cadastrar
        </Typography>
        <form onSubmit={handleSignUpSubmit}>
          <TextField
            fullWidth
            label="Email"
            variant="outlined"
            type="email"
            margin="normal"
            onChange={(e) => setEmail(e.target.value)}
            value={email}
          />
          <TextField
            fullWidth
            label="Senha"
            variant="outlined"
            type="password"
            margin="normal"
            onChange={(e) => setPassword(e.target.value)}
            value={password}
          />
          <Box mt={3} textAlign="center">
            <Button variant="contained" color="primary" type="submit" size="large">
              Cadastrar
            </Button>
          </Box>
        </form>
      </Paper>
    </Box>
  );
}
