import { createContext, useEffect, useState } from "react";
import { useParams } from "react-router";
import api from "../services/api";
import axios from "axios";
import { Snackbar, Alert } from "@mui/material";

export const OrderContext = createContext({});

export function OrderContextProvider(props) {
  const { id } = useParams();
  const [order, setOrder] = useState(null);

  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("error");

  const fetchOrder = async (orderId = id, controller = null) => {
    try {
      const config = controller ? { signal: controller.signal } : {};
      const response = await api.get(`orders/${orderId}`, config);
      setOrder(response.data);
    } catch (error) {
      if (!axios.isCancel(error) && error.code !== "ERR_CANCELED") {
        console.error("Erro ao buscar pedido:", error);
        showSnackbar(
          "Não foi possível carregar o pedido. Tente novamente mais tarde.",
          "error"
        );
      }
    }
  };

  const checkPayment = async () => {
    if (!order) return;

    try {
      const response = await api.get(
        `orders/${order.identifier}/invoice_status`
      );

      if (response.data.invoice_status === "paid") {
        setOrder({ ...order, invoice_status: "paid" });
        showSnackbar("O pagamento da sua fatura foi confirmado.", "success");
      } else {
        showSnackbar("O pagamento ainda não foi confirmado.", "info");
      }
    } catch (error) {
      console.error("Erro ao verificar pagamento:", error);
      showSnackbar(
        "Não foi possível verificar o pagamento. Tente novamente mais tarde.",
        "error"
      );
    }
  };

  const showSnackbar = (message, severity) => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setOpenSnackbar(true);
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  useEffect(() => {
    const controller = new AbortController();
    fetchOrder(id, controller).catch((error) => {
      console.error("Erro ao buscar o pedido:", error);
      showSnackbar("Erro ao carregar o pedido. Tente novamente.", "error");
    });

    return () => {
      controller.abort();
    };
  }, [id]);

  return (
    <OrderContext.Provider value={{ order, setOrder, fetchOrder, checkPayment }}>
      {props.children}

      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={snackbarSeverity}
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </OrderContext.Provider>
  );
}
